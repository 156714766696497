<template>
  <div>
    <b-form @submit.stop.prevent="formOnsubmit">
      <!-- Name input -->
      <b-form-group id="input-group-name" label="Nama:" label-for="input-name">
        <b-form-input
          id="input-name"
          v-model="form.name"
          placeholder="Nama Rukun Warga"
        ></b-form-input>
        <small class="text-danger">{{ error.name }}</small>
      </b-form-group>

      <!-- Name input -->
      <b-form-group
        id="input-group-head_name"
        label="Nama Ketua RW:"
        label-for="input-head_name"
      >
        <b-form-input
          id="input-head_name"
          v-model="form.head_name"
          placeholder="Nama Ketua RW"
        ></b-form-input>
        <small class="text-danger">{{ error.head_name }}</small>
      </b-form-group>

      <!-- Name input -->
      <b-form-group
        id="input-group-head_phone"
        label="No. WA Ketua RW:"
        label-for="input-head_phone"
      >
        <b-form-input
          id="input-head_phone"
          v-model="form.head_phone"
          placeholder="6282133456"
        ></b-form-input>
        <small class="text-danger">{{ error.head_phone }}</small>
      </b-form-group>

      <!-- <template v-if="purpose == 'add'">
        <label for="input-head_phone">No. WA Ketua RW</label>
        <b-input-group size="lg" prepend="+62" class="mb-5">
          <b-form-input
            id="input-head_phone"
            v-model="form.head_phone"
            placeholder="821334589"
          ></b-form-input>
          <small class="text-danger">{{ error.head_phone }}</small>
        </b-input-group>
      </template> -->

      <!-- Submit & Cancel button -->
      <b-button type="submit" variant="primary">Simpan</b-button>
      <b-button
        type="button"
        class="ml-2"
        variant="default"
        @click="$router.push('/masters/hamlets/list')"
      >
        Batal
      </b-button>
    </b-form>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  props: {
    form: Object,
    route: String,
    purpose: String,
  },

  data() {
    return {
      // Error
      error: {
        name: "",
        head_name: "",
        head_phone: "",
      },
    };
  },

  methods: {
    async formOnsubmit() {
      // if(this.form.hasOwnProperty("head_phone") && this.form.head_phone != ""){
      //     let first = Array.from(this.form.head_phone)
      //     if(first == 0){
      //         this.form.
      //     }
      // }
    //   if(this.purpose == 'add'){
    //       this.form.head_phone = "62" + this.form.head_phone;
    //   }

      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/masters/hamlets");
      }
    },
  },
};
</script>

<style>
</style>